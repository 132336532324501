<template>
  <Card title="角色管理">
    <div>
      <a-form-model
        layout="inline"
        @keyup.enter.native="query"
        style="margin-bottom: 2vh"
      >
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input v-model="form.code" placeholder="编码" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="名称" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="query">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button
                type="primary"
                @click="$router.push('/setting/role/add')"
                >新建</a-button
              >
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <a-table
        bordered
        :dataSource="list"
        @change="onChange"
        :loading="loading"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column key="code" title="编码" data-index="code" />
        <a-table-column key="name" title="名称" data-index="name" />
        <a-table-column key="remarks" title="描述" data-index="remarks" />

        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                @click.prevent="
                  $router.push('/setting/role/edit?id=' + text.id)
                "
                >编辑</a
              >
              <a href="#" class="danger" @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </Card>
</template>

<script>
import { fetchList, remove } from "@/api/setting/role";
export default {
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({ id: text.id }).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>